import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import images from "../../../assets/images";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUploadFileMutation,
} from "../../../redux/api/apiSlice";
import CustomAppBar from "../../components/CustomAppBar";
import CustomInput from "../../components/CustomInput";
import PasswordChangeForm from "./PasswordChangeForm";

const ProfileInformation = () => {
  const appBarData = [
    {
      title: "Client Management",
      icon: <SettingsIcon />,
      xp: "6000 XP",
      notifications: 90,
      profileImage: images.kimAvatar,
    },
  ];

  const { data: profileData, isLoading } = useGetProfileQuery();
  const [uploadFile] = useUploadFileMutation();
  const [updateProfile] = useUpdateProfileMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      state: "",
      city: "",
      zipCode: "",
      avatar: "",
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (profileData?.result?.data && !isInitialized) {
      const {
        first_name,
        last_name,
        email,
        phone_no,
        address,
        state,
        city,
        zip_code,
        image,
      } = profileData.result.data;

      reset({
        firstName: first_name,
        lastName: last_name,
        email: email,
        phone: phone_no,
        address: address,
        state: state,
        city: city,
        zipCode: zip_code,
        avatar: image,
      });

      setIsInitialized(true);
    }
  }, [profileData, reset, isInitialized]);

  const handleAvatarChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        setValue("avatar", e.target.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await uploadFile(formData).unwrap();
        console.log("File uploaded successfully:", response);
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();
    if (isInitialized) {
      setIsEditing(true);
    }
  };

  const handleSave = async (data) => {
    try {
      const response = await updateProfile({
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone_no: data.phone,
        address: data.address,
        state: data.state,
        city: data.city,
        zip_code: data.zipCode,
        image: data.avatar,
      }).unwrap();
      console.log("Profile updated successfully:", response);
      toast.success("Profile updated successfully!");
      // Display success toast
      setIsEditing(false);
    } catch (error) {
      console.error("Profile update failed:", error);
      toast.error("Failed to update profile.");
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box className="d-flex justify-content-between align-items-center flex-wrap">
      <CustomAppBar {...appBarData[0]} />
      <ToastContainer />
      <Box className="d-flex align-items-center justify-content-between w-100">
        <Typography className="sub_header font-poppins fw-500 text-black">
          Profile Information{" "}
        </Typography>
      </Box>
      <Box
        className="light-border border-r-8 bg-white"
        sx={{ p: 3, mt: 4, pb: 4 }}
      >
        <form id="profileForm" onSubmit={handleSubmit(handleSave)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box className="d-flex align-items-center justify-content-between w-100">
              <Box className="d-grid align-items-center justify-content-between">
                <Typography className="sub_header font-poppins fw-500 text-black">
                  Personal Details
                </Typography>
                <Typography className="f-16 font-poppins fw-500 color-para">
                  Update your personal information.
                </Typography>
              </Box>
              <Box sx={{ mt: 0, display: "flex", justifyContent: "flex-end" }}>
                {isEditing && isInitialized ? (
                  <Button
                    className="contact_btn m-0"
                    variant="contained"
                    color="primary"
                    type="submit"
                    form="profileForm"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    className="contact_btn m-0"
                    variant="contained"
                    onClick={handleEdit}
                    type="button"
                    disabled={!isInitialized}
                  >
                    Edit Profile
                  </Button>
                )}
              </Box>
            </Box>
            <Box position="relative">
              <Avatar
                src={control._formValues.avatar}
                sx={{ width: 100, height: 100, mb: 2 }}
              />
              {isEditing && (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{ position: "absolute", bottom: 0, right: 0 }}
                >
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    onChange={handleAvatarChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="icon-button-file">
                    <PhotoCamera />
                  </label>
                </IconButton>
              )}
            </Box>
            <Typography className="f-14 font-poppins fw-500 color-para" mb={3}>
              Recommended: Square JPG, PNG, or GIF, at least 200x200 pixels.
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="First Name"
                name="firstName"
                control={control}
                rules={{ required: "First name is required" }}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                disabled={!isEditing}
                placeholder="Enter first name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Last Name"
                name="lastName"
                control={control}
                rules={{ required: "Last name is required" }}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                disabled={!isEditing}
                placeholder="Enter last name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Email"
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
                disabled={!isEditing}
                placeholder="Enter email address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Phone"
                name="phone"
                control={control}
                rules={{ required: "Phone is required" }}
                error={!!errors.phone}
                helperText={errors.phone?.message}
                disabled={!isEditing}
                placeholder="Enter phone number"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                label="Address"
                name="address"
                control={control}
                rules={{ required: "Address is required" }}
                error={!!errors.address}
                helperText={errors.address?.message}
                disabled={!isEditing}
                placeholder="Enter address"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomInput
                label="State"
                name="state"
                control={control}
                rules={{ required: "State is required" }}
                error={!!errors.state}
                helperText={errors.state?.message}
                disabled={!isEditing}
                placeholder="Enter state"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomInput
                label="City"
                name="city"
                control={control}
                rules={{ required: "City is required" }}
                error={!!errors.city}
                helperText={errors.city?.message}
                disabled={!isEditing}
                placeholder="Enter city"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomInput
                label="Zip Code"
                name="zipCode"
                control={control}
                rules={{ required: "Zip code is required" }}
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message}
                disabled={!isEditing}
                placeholder="Enter zip code"
              />
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box
        className="light-border border-r-8 w-100 bg-white"
        sx={{ p: 2, mt: 4, pb: 4 }}
      >
        <Typography className="sub_header font-poppins fw-500 text-black">
          Password
        </Typography>
        <Typography className="f-16 font-poppins fw-500 color-para" mb={2}>
          Change your password or reset it if you've forgotten it.
        </Typography>
        <PasswordChangeForm
          onSubmit={(data) => console.log("Password Change Data:", data)}
          isEditing={isEditing}
        />
      </Box>
    </Box>
  );
};

export default ProfileInformation;
