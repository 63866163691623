import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetIntakeFormsQuery,
  useSubmitIntakeFormMutation,
} from "../../../../redux/api/apiSlice";
import CustomCheckbox from "../../../components/CustomCheckbox";
import CustomDropdown from "../../../components/CustomDropdown";
import CustomInput from "../../../components/CustomInput";

const HIPAAIntakeForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const formId = location.state?.formId;
  console.log(formId, "formId");

  const [submitIntakeForm, { isLoading, isError }] =
    useSubmitIntakeFormMutation();

  const {
    data: intakeFormsResponse,
    error,
    isLoading: isIntakeFormsLoading,
  } = useGetIntakeFormsQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      dob: "",
      gender: "",
      email: "",
      phone_no: "",
      contact_method: "phone_call",
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      emergency_contact_name: "",
      emergency_contact_phone: "",
      insurance_provider: "",
      policy_number: "",
      primary_concern: "",
      medicalHistory: "",
      current_medications: "",
      allergies: "",
      consent_to_treatment: false,
      consent_to_share_information: false,
    },
  });

  useEffect(() => {
    if (formId && intakeFormsResponse?.result?.data) {
      const intakeFormData = Array.isArray(intakeFormsResponse.result.data)
        ? intakeFormsResponse.result.data.find((form) => form.id === formId)
        : intakeFormsResponse.result.data.id === formId
        ? intakeFormsResponse.result.data
        : null;

      if (intakeFormData) {
        setValue("first_name", intakeFormData.first_name || "");
        setValue("last_name", intakeFormData.last_name || "");
        setValue("dob", intakeFormData.dob || "");
        setValue(
          "gender",
          intakeFormData.gender
            ? intakeFormData.gender.charAt(0).toUpperCase() +
                intakeFormData.gender.slice(1)
            : ""
        );
        setValue("email", intakeFormData.email || "");
        setValue("phone_no", intakeFormData.phone_no || "");
        setValue(
          "contact_method",
          intakeFormData.contact_method || "phone_call"
        );
        setValue("street_address", intakeFormData.street_address || "");
        setValue("city", intakeFormData.city || "");
        setValue("state", intakeFormData.state || "");
        setValue("zip_code", intakeFormData.zip_code || "");
        setValue(
          "emergency_contact_name",
          intakeFormData.emergency_contact_name || ""
        );
        setValue(
          "emergency_contact_phone",
          intakeFormData.emergency_contact_phone || ""
        );
        setValue("insurance_provider", intakeFormData.insurance_provider || "");
        setValue("policy_number", intakeFormData.policy_number || "");
        setValue("primary_concern", intakeFormData.primary_concern || "");
        setValue("medicalHistory", intakeFormData.medicalHistory || "");
        setValue(
          "current_medications",
          intakeFormData.current_medications || ""
        );
        setValue("allergies", intakeFormData.allergies || "");
        setValue(
          "consent_to_treatment",
          intakeFormData.consent_to_treatment === true || false
        );
        setValue(
          "consent_to_share_information",
          intakeFormData.consent_to_share_information === true || false
        );
      }
    }
  }, [formId, intakeFormsResponse, setValue]);

  const consent_to_treatment = watch("consent_to_treatment");
  const consent_to_share_information = watch("consent_to_share_information");

  const onSubmit = async (data) => {
    if (!data.consent_to_treatment || !data.consent_to_share_information) {
      console.error("Consent not given for Treatment or Sharing Information");
      return;
    }

    const payload = {
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      dob: data.dob || "",
      gender: data.gender ? data.gender.toLowerCase() : "",
      email: data.email || "",
      phone_no: data.phone_no || "",
      contact_method: data.contact_method || "phone_call",
      street_address: data.street_address || "",
      city: data.city || "",
      state: data.state || "",
      zip_code: data.zip_code || "",
      emergency_contact_name: data.emergency_contact_name || "",
      emergency_contact_phone: data.emergency_contact_phone || "",
      insurance_provider: data.insurance_provider || "",
      policy_number: data.policy_number || "",
      primary_concern: data.primary_concern || "",
      medicalHistory: data.medicalHistory || "",
      current_medications: data.current_medications || "",
      allergies: data.allergies || "",
      consent_to_treatment: data.consent_to_treatment === true,
      consent_to_share_information: data.consent_to_share_information === true,
    };

    console.log("Payload to be submitted:", payload);

    try {
      await submitIntakeForm(payload).unwrap();
      console.log("Form submitted successfully");
      reset();
      navigate("/dashboard/hippa-compliance/edit-hippa-compliance");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  if (isIntakeFormsLoading) {
    return <Typography>Loading...</Typography>;
  }
  // if (error) {
  //   return <Typography>Error: {error.message}</Typography>;
  // }

  return (
    <Box
      sx={{
        // maxWidth: "1200px",
        // margin: "0 auto",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "var(--color-lightSky)",
          padding: "24px",
          borderRadius: "8px 8px 0 0",
          marginBottom: 3,
          borderBottom: "1px solid rgb(224, 224, 224)",
        }}
      >
        <Box
          sx={{ borderRadius: "8px" }}
          className="d-flex align-items-center gap-1"
        >
          <ShieldOutlinedIcon
            sx={{ color: "var(--color-darkSky)", fontSize: "25px" }}
          />
          <Typography variant="h6" className="fw-600 text-darksky">
            HIPAA-Compliant Intake Form
          </Typography>
        </Box>

        <Typography className="f-18 color-para font-poppins fw-500" mt={1}>
          Please complete this form to provide us with your information. All
          information is protected under HIPAA regulations.
        </Typography>
      </Box>

      <Box className="hippa-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              marginTop: 2,
              marginBottom: 3,
            }}
            className="f-20 fw-600 text-black"
          >
            Personal Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="First Name"
                name="first_name"
                control={control}
                rules={{ required: "First name is required" }}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Last Name"
                name="last_name"
                control={control}
                rules={{ required: "Last name is required" }}
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Date of Birth"
                name="dateOfBirth"
                type="date"
                control={control}
                rules={{ required: "Date of birth is required" }}
                error={!!errors.dateOfBirth}
                helperText={errors.dateOfBirth?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomDropdown
                label="Gender"
                name="gender"
                control={control}
                rules={{ required: "Gender is required" }}
                error={!!errors.gender}
                helperText={errors.gender?.message}
                options={["Male", "Female", "Other"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Email"
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Phone Number"
                name="phoneNumber"
                control={control}
                rules={{
                  required: "Phone number is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                }}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Typography className="f-14 fw-600 text-black" gutterBottom>
                  Preferred Contact Method
                </Typography>
                <RadioGroup
                  row
                  {...register("preferredContactMethod")}
                  defaultValue="phone_call"
                >
                  <FormControlLabel
                    value="phone_call"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "var(--color-darkSky)",
                          },
                        }}
                      />
                    }
                    label="Phone Call"
                    className="f-14 font-poppins fw-500 color-para"
                  />
                  <FormControlLabel
                    value="email"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "var(--color-darkSky)",
                          },
                        }}
                      />
                    }
                    label="Email"
                    className="f-14 font-poppins fw-500 color-para"
                  />
                  <FormControlLabel
                    value="text"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "var(--color-darkSky)",
                          },
                        }}
                      />
                    }
                    label="Text Message"
                    className="f-14 font-poppins fw-500 color-para"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Typography
            className="f-20 fw-600 text-black"
            gutterBottom
            sx={{ marginTop: 4 }}
            mb={2}
          >
            Address
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomInput
                label="Street Address"
                name="streetAddress"
                control={control}
                rules={{ required: "Street address is required" }}
                error={!!errors.streetAddress}
                helperText={errors.streetAddress?.message}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomInput
                label="City"
                name="city"
                control={control}
                rules={{ required: "City is required" }}
                error={!!errors.city}
                helperText={errors.city?.message}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomInput
                label="State"
                name="state"
                control={control}
                rules={{ required: "State is required" }}
                error={!!errors.state}
                helperText={errors.state?.message}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomInput
                label="Zip Code"
                name="zipCode"
                control={control}
                rules={{
                  required: "Zip code is required",
                  pattern: {
                    value: /^[0-9]{5}$/,
                    message: "Please enter a valid 5-digit zip code",
                  },
                }}
                error={!!errors.zipCode}
                helperText={errors.zipCode?.message}
              />
            </Grid>
          </Grid>

          <Typography
            className="f-20 fw-600 text-black"
            gutterBottom
            sx={{ marginTop: 4, mb: 2 }}
          >
            Emergency Contact
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Emergency Contact Name"
                name="emergencyContactName"
                control={control}
                rules={{ required: "Emergency contact name is required" }}
                error={!!errors.emergencyContactName}
                helperText={errors.emergencyContactName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Emergency Contact Phone"
                name="emergencyContactPhone"
                control={control}
                rules={{
                  required: "Emergency contact phone is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                }}
                error={!!errors.emergencyContactPhone}
                helperText={errors.emergencyContactPhone?.message}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Insurance Information (Optional)
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Insurance Provider"
                name="insuranceProvider"
                control={control}
                rules={{
                  required: false,
                  minLength: {
                    value: 2,
                    message: "Insurance provider must be at least 2 characters",
                  },
                }}
                error={!!errors.insuranceProvider}
                helperText={errors.insuranceProvider?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                label="Policy Number"
                name="policyNumber"
                control={control}
                rules={{
                  required: false,
                  minLength: {
                    value: 2,
                    message: "Policy number must be at least 2 characters",
                  },
                }}
                error={!!errors.policyNumber}
                helperText={errors.policyNumber?.message}
              />
            </Grid>
          </Grid>

          <Typography
            className="f-20 fw-600 text-black"
            gutterBottom
            sx={{ marginTop: 4, mb: 2 }}
          >
            Health Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomInput
                label="Primary Concern"
                name="primaryConcern"
                control={control}
                rules={{ required: "Primary concern is required" }}
                error={!!errors.primaryConcern}
                helperText={errors.primaryConcern?.message}
                multiline
                rows={4}
                placeholder="Please describe your primary reason for seeking care"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                label="Medical History"
                name="medicalHistory"
                control={control}
                rules={{ required: "Medical history is required" }}
                error={!!errors.medicalHistory}
                helperText={errors.medicalHistory?.message}
                multiline
                rows={4}
                placeholder="Please list any relevant medical history"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                label="Current Medications"
                name="currentMedications"
                control={control}
                rules={{
                  required: "Current medications information is required",
                }}
                error={!!errors.currentMedications}
                helperText={errors.currentMedications?.message}
                multiline
                rows={4}
                placeholder="Please list all current medications and supplements"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                label="Allergies"
                name="allergies"
                control={control}
                rules={{ required: "Allergies information is required" }}
                error={!!errors.allergies}
                helperText={errors.allergies?.message}
                multiline
                rows={4}
                placeholder="Please list any allergies"
              />
            </Grid>
          </Grid>

          <Typography
            className="f-20 fw-600 text-black"
            gutterBottom
            sx={{ marginTop: 4, mb: 2 }}
          >
            Consent
          </Typography>

          <Grid
            item
            xs={12}
            className="text-start d-flex align-items-start border-r-8 warm-border bg-warm gap-1"
            p={2}
            mb={2}
          >
            <ReportProblemIcon sx={{ fontSize: "18px" }} />
            <Box>
              <Typography
                variant="body2"
                className="f-20 fw-500 font-poppins text-black"
              >
                Important
                <br />
                <span className="f-14 fw-400 color-para">
                  Please read and acknowledge the following consent statements.
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            className="text-start d-flex align-items-start border-r-8 light-border"
            p={2}
            mb={2}
          >
            <CustomCheckbox
              label=""
              checked={consent_to_treatment}
              onChange={(checked) => setValue("consent_to_treatment", checked)}
            />
            <Box mt={1}>
              <Typography variant="body2" component="div">
                <span className="fw-500 font-poppins f-18">
                  Consent to Treatment
                </span>
                <br />I consent to receive treatment from this wellness provider
                and understand that I can withdraw consent at any time.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            className="text-start d-flex align-items-start border-r-8 light-border"
            p={2}
          >
            <CustomCheckbox
              label=""
              checked={consent_to_share_information}
              onChange={(checked) =>
                setValue("consent_to_share_information", checked)
              }
            />
            <Box mt={1}>
              <Typography variant="body2" component="div">
                <span className="fw-500 font-poppins f-18">
                  Consent to Share Information
                </span>
                <br />I consent to the sharing of my information with other
                healthcare providers as necessary for my treatment.
              </Typography>
            </Box>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            className="contact_btn f-18 radius-8 w-100"
            color="primary"
            sx={{ mt: 2 }}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
          <Box className="d-flex align-items-center gap-1" my={1} pb={2}>
            <ShieldOutlinedIcon sx={{ fontSize: "20px" }} />
            <Typography
              variant="h6"
              className="font-poppins f-14 fw-500 color-para"
            >
              This form is encrypted and complies with HIPAA regulations for the
              protection of your personal health information.
            </Typography>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default HIPAAIntakeForm;
