import { Box } from "@mui/material";
import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import {
  features,
  skilledData,
  skilledDetailData,
} from "./assets/data/aboutus";
import {
  educationPodcast,
  faceFearData,
  faceFearDetail,
  podcastData,
  podcastStructure,
  podcastStructureData,
  sampleQuestions,
  surveyData,
} from "./assets/data/anchorPodcast";
import {
  compassData,
  guiderData,
  logBookData,
  worksDetailData,
} from "./assets/data/caption";
import {
  accordionData,
  gemsModule,
  growthData,
  holisticCardsData,
  infoCardData,
  leadershipData,
  leadershipModule,
  legalData,
  lifeData,
  moduleData,
  pageData,
  personalData,
  reviewsData,
  strengthData,
  trasuresData,
  weekAccordianData,
  weekModule,
  whyItWorks,
} from "./assets/data/curriculum";
import {
  classDetails,
  drivesChangeData,
  drivesData,
  duiJourneyData,
  holisticBannerData,
  programsAccordianData,
  programsData,
} from "./assets/data/holisticJourney";
import {
  confidenceData,
  handbookaccordionData,
  highlightsAccordianData,
  highlightsData,
  legalHandbookData,
  reviewhandlebookDAta,
  whyItMatetrs,
} from "./assets/data/legalbook";
import { pricingPlans } from "./assets/data/pricing";
import { poweredData, poweredDetailData } from "./assets/data/sponser";
import {
  categoriesData,
  categoriesDetailData,
  engagementData,
  streetData,
  streetDetailData,
} from "./assets/data/treasure";
import "./assets/style.css";
import PrivateRoute from "./PrivateRoute";
import AboutUs from "./website/AboutUs";
import "./website/AboutUs/about.css";
import Sponsors from "./website/AboutUs/Sponsors";
import Captains from "./website/Captains";
import ContactUs from "./website/ContactUs";
import CreateAccount from "./website/CreateAccount";
import Dashboard from "./website/Dashboard";
import FinalQuiz from "./website/Dashboard/FinalQuiz";
import HippaCompliance from "./website/Dashboard/HippaCompliance";
import HippaEditForm from "./website/Dashboard/HippaCompliance/HippaEditForm";
import ProfileInformation from "./website/Dashboard/HippaCompliance/ProfileInformation";
import DashboardCourses from "./website/Dashboard/pages/DashboardCources";
import DashboardHome from "./website/Dashboard/pages/DashboardHome";
import EducationLevel from "./website/Dashboard/pages/EducationLevel";
import EndQuiz from "./website/Dashboard/pages/EndQuiz";
import EnrolledCourses from "./website/Dashboard/pages/EnrolledCourses";
import HowWorks from "./website/Dashboard/pages/HowWorks";
import PodcastLearning from "./website/Dashboard/PodcastLearning";
import PodcastEpisods from "./website/Dashboard/PodcastLearning/pages/PodcastEpisods";
import QuizGame from "./website/Dashboard/QuizGame";
import TwelveWeeksPlan from "./website/Dashboard/TwelveWeeksPlan";
import ExplorerGuide from "./website/ExplorerGuide";
import DailyTreasure from "./website/ExplorerGuide/ExplorerGuideDetail/components/DailyTreasure";
import EducationalPodcast from "./website/ExplorerGuide/ExplorerGuideDetail/EducationalPodcast";
import HolisticJourney from "./website/ExplorerGuide/ExplorerGuideDetail/HolisticJourney";
import LegalKnowledges from "./website/ExplorerGuide/ExplorerGuideDetail/LegalKnowledge";
import SeaDiscovery from "./website/ExplorerGuide/ExplorerGuideDetail/SeaDiscovery";
import TwelveWeek from "./website/ExplorerGuide/ExplorerGuideDetail/TwelveWeek";
import Footer from "./website/Footer";
import ForgotPassword from "./website/ForgotPassword";
import Home from "./website/Home";
import Login from "./website/Login";
import LoginAccount from "./website/LoginAccount";
import NavbarApp from "./website/NavbarApp";
import NotFound from "./website/NotFound";
import OTPVerification from "./website/OTPVerification";
import PasswordChanged from "./website/PasswordChanged";
import PricingPlans from "./website/PricingPlans";
import ResetPassword from "./website/ResetPassword";
import RewardStore from "./website/RewardStore";
import Signup from "./website/Signup";
import TreasureChest from "./website/TreasureChest";

const AnimatedPageWrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <div>{children}</div>;
};

const isPrivateRoute =
  location.pathname.startsWith("/dashboard") ||
  location.pathname.startsWith("/curriculum");

function App() {
  return (
    <Router>
      {/* <NavbarApp /> */}
      {!isPrivateRoute && <NavbarApp />}
      <AnimatedPageWrapper>
        <Box
          component="main"
          sx={{
            py: 3,
            width: "100%",
            mt: "42px",
            background: "var(--color-light)",
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/login-account" element={<LoginAccount />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp-verification" element={<OTPVerification />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/password-changed" element={<PasswordChanged />} />

            {/* Protected Routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="home" element={<DashboardHome />} />
                <Route
                  path="twelve-weeks-plans"
                  element={<TwelveWeeksPlan />}
                />

                <Route path="podcast-learning" element={<PodcastLearning />} />

                <Route
                  path="podcast-learning/podcast-episods"
                  element={<PodcastEpisods />}
                />

                <Route path="hippa-compliance" element={<HippaCompliance />} />
                <Route
                  path="hippa-compliance/edit-hippa-compliance"
                  element={<HippaEditForm />}
                />
                <Route
                  path="profile-information"
                  element={<ProfileInformation />}
                />

                <Route path="courses" element={<DashboardCourses />} />
                <Route path="how-it-works" element={<HowWorks />} />
                <Route path="enrolled-courses" element={<EnrolledCourses />} />
                <Route
                  path="enrolled-courses/education-level"
                  element={<EducationLevel />}
                />
                <Route
                  path="enrolled-courses/final-quiz"
                  element={<FinalQuiz />}
                />
                <Route
                  path="enrolled-courses/quiz-game"
                  element={<QuizGame />}
                />
                <Route path="enrolled-courses/end-quiz" element={<EndQuiz />} />
              </Route>
              <Route
                path="/curriculum"
                element={
                  <ExplorerGuide holisticCardsData={holisticCardsData} />
                }
              />
              <Route path="/reward-store" element={<RewardStore />} />
            </Route>

            {/* Public Routes */}
            <Route
              path="/about"
              element={
                <AboutUs
                  podcastData={podcastData}
                  skilledData={skilledData}
                  skilledDetailData={skilledDetailData}
                  features={features}
                />
              }
            />
            <Route
              path="/sponsers"
              element={
                <Sponsors
                  poweredData={poweredData}
                  poweredDetailData={poweredDetailData}
                />
              }
            />
            <Route
              path="/captains-quarters"
              element={
                <Captains
                  worksDetailData={worksDetailData}
                  guiderData={guiderData}
                  logBookData={logBookData}
                  compassData={compassData}
                />
              }
            />
            <Route
              path="/treasure-chest"
              element={
                <TreasureChest
                  streetData={streetData}
                  streetDetailData={streetDetailData}
                  categoriesData={categoriesData}
                  categoriesDetailData={categoriesDetailData}
                  engagementData={engagementData}
                />
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/pricing-plans"
              element={<PricingPlans pricingPlans={pricingPlans} />}
            />
            <Route
              path="/explorer-guide"
              element={<ExplorerGuide holisticCardsData={holisticCardsData} />}
            />

            {/* Curriculum Routes */}
            <Route
              path="/curriculum/sea-discovery"
              element={
                <SeaDiscovery
                  growthData={growthData}
                  pageData={pageData}
                  moduleData={moduleData}
                  accordionData={accordionData}
                />
              }
            />
            <Route
              path="/curriculum/twelve-weeks-plan"
              element={
                <TwelveWeek
                  accordionData={accordionData}
                  reviewsData={reviewsData}
                  weekAccordianData={weekAccordianData}
                  weekModule={weekModule}
                />
              }
            />
            <Route
              path="/curriculum/daily-treasure-hunting"
              element={
                <DailyTreasure
                  trasuresData={trasuresData}
                  gemsModule={gemsModule}
                  infoCardData={infoCardData}
                  leadershipModule={leadershipModule}
                  leadershipData={leadershipData}
                  lifeData={lifeData}
                  reviewsData={reviewsData}
                  accordionData={accordionData}
                  whyItWorks={whyItWorks}
                  strengthData={strengthData}
                  personalData={personalData}
                />
              }
            />
            <Route
              path="/curriculum/legal-knowledge"
              element={
                <LegalKnowledges
                  legalData={legalData}
                  legalHandBook={legalHandbookData}
                  whyItMatetrs={whyItMatetrs}
                  pageData={confidenceData}
                  accordionData={handbookaccordionData}
                  reviewhandlebookDAta={reviewhandlebookDAta}
                  highlightsAccordianData={highlightsAccordianData}
                  highlightsData={highlightsData}
                />
              }
            />
            <Route
              path="/curriculum/holistic-journey"
              element={
                <HolisticJourney
                  holisticBannerData={holisticBannerData}
                  duiJourneyData={duiJourneyData}
                  programsData={programsData}
                  programsAccordianData={programsAccordianData}
                  drivesData={drivesData}
                  classDetails={classDetails}
                  drivesChangeData={drivesChangeData}
                  accordionData={handbookaccordionData}
                  reviewhandlebookDAta={reviewhandlebookDAta}
                />
              }
            />
            <Route
              path="/curriculum/educational-podcast"
              element={
                <EducationalPodcast
                  podcastData={podcastData}
                  educationPodcast={educationPodcast}
                  faceFearData={faceFearData}
                  faceFearDetail={faceFearDetail}
                  surveyData={surveyData}
                  sampleQuestions={sampleQuestions}
                  accordionData={handbookaccordionData}
                  reviewhandlebookDAta={reviewhandlebookDAta}
                  podcastStructure={podcastStructure}
                  podcastStructureData={podcastStructureData}
                />
              }
            />

            {/* Catch all route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </AnimatedPageWrapper>
      {/* <Footer /> */}
      {!isPrivateRoute && <Footer />}
    </Router>
  );
}

export default App;
