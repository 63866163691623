import {
  Help,
  Home,
  InsertDriveFile,
  Menu as MenuIcon,
  People,
} from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import VideocamIcon from '@mui/icons-material/Videocam';
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Avatar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import images from "../../assets/images";

const drawerWidth = 240;
const collapsedDrawerWidth = 65;

const menuItems = [
  { text: "Home", icon: <Home />, route: "/dashboard/home" },
  {
    text: "12-Week Daily Journal",
    icon: <MenuBookIcon />,
    route: "/dashboard/twelve-weeks-plans",
  },
  { text: "Podcast Learning", icon: <VideocamIcon />, route: "/dashboard/podcast-learning" },
  // { text: "Events", icon: <Event />, route: "/dashboard/events" },
  {
    text: "HIPPA Compliance",
    icon: <DescriptionIcon />,
    route: "/dashboard/hippa-compliance",
  },
  {
    text: "Enrolled Courses",
    icon: <PlayCircleOutlineIcon />,
    route: "/dashboard/enrolled-courses",
  },
];

const additionalMenuItems = [
  // { text: "Community", icon: <People />, route: "/dashboard/community" },
  { text: "Help Center", icon: <Help />, route: "/dashboard/help" },
  // { text: "Settings", icon: <Settings />, route: "/dashboard/settings" },
  {
    text: "How it Works",
    icon: <InsertDriveFile />,
    route: "/dashboard/how-it-works",
  },
];

const Sidebar = ({ open, onToggle }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Drawer
      variant={isLargeScreen ? "permanent" : "temporary"}
      open={isLargeScreen ? true : open}
      onClose={onToggle}
      sx={{
        height: "100vh",
        mt: "80px",
        width: isLargeScreen
          ? open
            ? drawerWidth
            : collapsedDrawerWidth
          : drawerWidth,
        flexShrink: 0,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [`& .MuiDrawer-paper`]: {
          width: isLargeScreen
            ? open
              ? drawerWidth
              : collapsedDrawerWidth
            : drawerWidth,
          boxSizing: "border-box",
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <Toolbar>
        <Link to="/dashboard/home">
          <img
            className="dashboard_logo objet-fit-contain mb-1"
            src={images.dLogo}
            alt="logo"
          />
        </Link>
      </Toolbar>
      <List sx={{ px: 2 }}>
        {menuItems.map(({ text, icon, route }) => (
          <ListItem
            button
            key={text}
            component={Link}
            to={route}
            className="dashboard_items"
          >
            <ListItemIcon className="dashboard_items f-18 fw-500 font-poppins color-para">
              {icon}
            </ListItemIcon>
            {open && (
              <ListItemText
                className="dashboard_items f-18 fw-500 font-poppins color-para"
                primary={text}
              />
            )}
          </ListItem>
        ))}
      </List>
      <List sx={{ px: 2, flexGrow: 1 }}>
        {additionalMenuItems.map(({ text, icon, route }) => (
          <ListItem
            button
            key={text}
            component={Link}
            to={route}
            className="dashboard_items"
          >
            <ListItemIcon className="f-18 fw-500 font-poppins color-para dashboard_items">
              {icon}
            </ListItemIcon>
            {open && (
              <ListItemText
                className="f-18 fw-500 font-poppins color-para dashboard_items"
                primary={text}
              />
            )}
          </ListItem>
        ))}
      </List>

      {/* User Profile Section */}
      <Box sx={{ p: 2, mt: "auto", display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
          <Avatar
            src={images.kimAvatar}
            alt="Kim Sutherland"
            sx={{ width: 40, height: 40, mr: open ? 2 : 0 }}
          />
          {open && (
            <Box>
              <Typography className="fw-500 font-poppins text-black f-18">
                Kim Sutherland
              </Typography>
              <Typography className="fw-400 font-poppins color-para f-12">
                jonas@mail.com
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

const Dashboard = () => {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Sidebar open={open} onToggle={() => setOpen(!open)} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 2 }}>
        {!isLargeScreen && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setOpen(!open)}
            sx={{ mb: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
