import { Box } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import NavbarApp from "./website/NavbarApp";

const PrivateRoute = () => {
  const token = localStorage.getItem("authToken");
  console.log("Token in PrivateRoute:", token);

  if (!token) {
    return <Navigate to="/login-account" />;
  }

  return (
    <Box mb={5}>
      <NavbarApp />
      <Outlet />
    </Box>
  );
};

export default PrivateRoute;
